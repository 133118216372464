import React, { Component } from "react"
import { graphql } from "gatsby"
import styles from "./appraisal.module.css"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Center from "../../components/center/center"

class Appraisal extends Component {
  render() {
    return (
      <Center>
        <StaticQuery
          query={graphql`
            query {
              markdownRemark(frontmatter: { type: { eq: "appraisalText" } }) {
                html
                frontmatter {
                  title
                }
              }
              file(relativePath: { eq: "smartphonescreen.png" }) {
                childImageSharp {
                  fixed(width: 350) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => (
            <div className={styles.appraisalContainer}>
              <div className={styles.appraisalText}>
                <h2 className={styles.appraisalTitle}>
                  {data.markdownRemark.frontmatter.title}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html,
                  }}
                />
              </div>
              <div className={styles.appraisalImg}>
                <Img fixed={data.file.childImageSharp.fixed} alt="AppScreen" />
              </div>
            </div>
          )}
        />
      </Center>
    )
  }
}

export default Appraisal
