import React, { Component } from "react"
import { graphql } from "gatsby"
import styles from "./displaySection.module.css"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Center from "../../components/center/center"

class DisplaySection extends Component {
  render() {
    return (
      <Center>
        <StaticQuery
          query={graphql`
            query {
              markdownRemark(
                frontmatter: { type: { eq: "displaySectionText" } }
              ) {
                html
                frontmatter {
                  title
                }
              }
              file(relativePath: { eq: "chart.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => (
            <div className={styles.displaySectionContainer}>
              <div className={styles.displaySectionText}>
                <h2 className={styles.displaySectionTitle}>
                  {data.markdownRemark.frontmatter.title}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html,
                  }}
                />
              </div>
              <div className={styles.displaySectionImg}>
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  alt="Menon Chart"
                />
              </div>
            </div>
          )}
        />
      </Center>
    )
  }
}

export default DisplaySection
