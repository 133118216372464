import React, { Component } from "react"
import Button from "../../components/buttons/button"
import styles from "./contact.module.css"

class Contact extends Component {
  render() {
    return (
      <div className={styles.contactContainer}>
        <p>Noch Fragen, Inputs, Ideen oder Kapital?</p>
        <div className={styles.contactButton}>
          <Button buttonText={"Mehr erfahren"} link="/about" />
        </div>
      </div>
    )
  }
}

export default Contact
