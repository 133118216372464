import React, { Component } from "react"
import styles from "./fullScreen.module.css"
class FullScreen extends Component {
    render() {
        return <div ref={this.ref} className={styles.fullScreen + " " + this.props.className}>
            <div className={styles.fullScreenContent}>
                {this.props.children}
            </div>
        </div>
    }
}

export default FullScreen;