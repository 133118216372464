import React, { Component } from "react"
import styles from "./home.module.css"
import FullScreen from "../../components/full_screen/fullScreen"
import Center from "../../components/center/center"
import ScrollToButton from "../../components/buttons/scroll_to_button"
import Logo from "../../img/logo_blue.svg"

class Home extends Component {
  render() {
    return (
      <FullScreen className={styles.homeScreen}>
        <Center>
          <div className={styles.homeContainer}>
            <div className={styles.logoWrapper}>
              <Logo className={styles.icon}/>
            </div>
            <h1>Menon Education</h1>
            <span className={styles.subtitle}>
              Bildung im digitalen Zeitalter
                </span>
          </div>
        </Center>
        <ScrollToButton reference={this.props.scrollToReference} />
      </FullScreen>
    )
  }
}

export default Home
