import React, { Component } from "react"
import { graphql } from "gatsby"
import styles from "./challenges.module.css"
import { StaticQuery } from "gatsby"
import Center from "../../components/center/center"

class Challenges extends Component {
  render() {
    return (
      <Center>
        <StaticQuery
          query={graphql`
            query {
              markdownRemark(frontmatter: { type: { eq: "challengesText" } }) {
                html
                frontmatter {
                  title
                }
              }
            }
          `}
          render={data => (
            <div className={styles.challengesContainer}>
              <div className={styles.challengesText}>
                <h2 className={styles.challengesTitle}>{data.markdownRemark.frontmatter.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html,
                  }}
                />
              </div>
            </div>
          )}
        />
      </Center>
    )
  }
}

export default Challenges
