import React, { Component } from "react"
import styles from "./teaser.module.css"
import { StaticQuery } from "gatsby"
import InfoBox from "../../components/info_box/infoBox"

class Teaser extends Component {
  render() {
    return (
      <InfoBox>
        <StaticQuery
          query={graphql`
            query {
              markdownRemark(frontmatter: { type: { eq: "menonTeaser" } }) {
                html
                frontmatter {
                  title
                }
              }
            }
          `}
          render={data => (
            <div className={styles.teaserText}>
              <div className={styles.bigger}>
                {data.markdownRemark.frontmatter.title}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.html,
                }}
              />
            </div>
          )}
        />
      </InfoBox>
    )
  }
}

export default Teaser
