import React, { Component } from "react"
import styles from "./infoBox.module.css"

class InfoBox extends Component {
  render() {
    return (
      <div className={styles.infoBoxWrapper}>
        <div className={styles.infoBoxContent}>
          <div className={styles.infoBoxText}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default InfoBox
