import React,  { Component } from "react";
import ReactDOM from 'react-dom';
import styles from "./button.module.css"
import Icon from "../../img/arrow_down.svg"

class ScrollToButton extends Component {
    scrollToReference(){
        let element = ReactDOM.findDOMNode(this.props.reference.current);
        let offsetTop = element.offsetTop - 64
        window.scrollTo(0, offsetTop)
    }

    render() {
        return <button className={styles.scrollToButton + " " + styles[this.props.className]} onClick={this.scrollToReference.bind(this)}><Icon /></button>
    }
}

export default ScrollToButton