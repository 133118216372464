import React, { Component } from "react"
import { graphql } from "gatsby"
import styles from "./development.module.css"
import Center from "../../components/center/center"
import { StaticQuery } from "gatsby"

class Development extends Component {
  render() {
    return (
      <Center>
        <StaticQuery
          query={graphql`
            query {
              markdownRemark(frontmatter: { type: { eq: "developmentText" } }) {
                html
                frontmatter {
                  title
                }
              }
            }
          `}
          render={data => (
            <div className={styles.developmentContainer}>
              <div className={styles.developmentText}>
                <h2 className={styles.developmentTitle}>
                  {data.markdownRemark.frontmatter.title}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html,
                  }}
                />
              </div>
              {/* <div className={styles.developmentImg}>
                <Img fixed={data.file.childImageSharp.fixed} alt="Cycle" />
              </div> */}
            </div>
          )}
        />
      </Center>
    )
  }
}

export default Development
