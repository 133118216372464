import React, { Component } from "react"
import styles from "./instruments.module.css"
import FullScreen from "../../components/full_screen/fullScreen"
import Center from "../../components/center/center"

class Instruments extends Component {
  render() {
    return (
      <FullScreen>
        <Center>
          <div ref={this.props.reference} className={styles.container}>
            <div className={styles.informationText}>
              Bildungseinrichtungen fehlen derzeit{" "}
              <span className={styles.bigger}>Instrumente</span>, um diese
              Kompetenzen zu erheben und sie damit auch gezielt entwickeln zu
              können.
            </div>
          </div>
        </Center>
      </FullScreen>
    )
  }
}

export default Instruments
