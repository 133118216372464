import React, { Component } from "react"
import Home from "../screens/home"
import Competencies from "../screens/competencies"
import Instruments from "../screens/instruments"
import Appraisal from "../screens/appraisal"
import DisplaySection from "../screens/displaySection"
import Development from "../screens/development"
import Contact from "../screens/contact"
import styles from "../styles/home.module.css"
import Challenges from "../screens/challenges"
import Teaser from "../screens/teaser"

class HomePage extends Component {
  render() {
    let scrollReferenceCompetencies = React.createRef()
    let scrollReferenceInstruments = React.createRef()
    return (
      <div className={styles.homePage}>
        <Home scrollToReference={scrollReferenceCompetencies} />
        <Competencies
          reference={scrollReferenceCompetencies}
          scrollToReference={scrollReferenceInstruments}
        />
        <div class="main-content">
          <Instruments reference={scrollReferenceInstruments} />
          <Teaser />
          <Challenges />
          <Appraisal />
          <DisplaySection />
          <Development />
          <Contact />
        </div>
      </div>
    )
  }
}

export default HomePage
