import React, { Component } from "react"
import styles from "./slider.module.css"

class Slider extends Component {
  render() {
    return (
      <div className={styles.sliderBox}>
        <div className={styles.sliderContent}>
          <div className={styles.sliderItem}>
            <span className={styles.sliderWord}>Kreativität</span>
          </div>
          <div className={styles.sliderItem}>
            <span className={styles.sliderWord}>Kommunikation</span>
          </div>
          <div className={styles.sliderItem}>
            <span className={styles.sliderWord}>Kritisches Denken</span>
          </div>
          <div className={styles.sliderItem}>
            <span className={styles.sliderWord}>Kollaboration</span>
          </div>
          <div className={styles.sliderItem}>
            <span className={styles.sliderWord}>Kreativität</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Slider
