import React, { Component } from "react"
import styles from "./competencies.module.css"
import Slider from "../../components/slider/slider"
import Center from "../../components/center/center"
import ScrollToButton from "../../components/buttons/scroll_to_button"


class Competencies extends Component {
  render() {
    return (
      <div ref={this.props.reference} className={styles.competencies}>
        <Center>
          <div className={styles.text}>
            <span className={styles.homeScreenText}>
              Das digitale Zeitalter fordert von uns Menschen wichtige{" "}
              <span className={styles.homeScreenTitle}>
                überfachliche Kompetenzen.
              </span>
            </span>
          </div>
          <Slider />
        </Center>
        <ScrollToButton className="competencies" reference={this.props.scrollToReference} />
      </div>
    )
  }
}

export default Competencies
